<template>
    <page
        id="plans-list"
        :title="$root.translate('Plan:::Plans', {}, 2)"
        :is-root="true"
        :status="page_status"
        :waiting="waiting"
        @statused="statused"
    >
        <data-table
            id="plans-list"
            :columns="visible_columns"
            :rows="filtered_rows"
            :search="state.search"
            :total="state.total"
            :found="state.found"
            :current-page="state.page"
            :total-pages="nb_pages"
            :can-add="true"
            :export-url="export_url"
            @sort="sort_by"
            @search="search"
            @page="goto_page"
            @add="record_create(entity)"
        >
            <template
                v-slot:summary="{ row }"
            >
                <h6
                    :class="{ 'text-strikethrough': !!row.deleted_at }"
                >
                    {{ row.name }}
                </h6>
                <div
                    :class="{ 'text-strikethrough': !!row.deleted_at }"
                >
                    {{ stripe_amount(row.amount, row.interval, row.interval_count) }}
                    <span v-if="!!row.trial_period_days">{{ $root.translate("One day:::{count} days", { count: row.trial_period_days }, row.trial_period_days) }}</span>
                    <span v-else class="text-gray">{{ $root.translate("No Trial") }}</span>
                </div>
                <form-toggle-input
                    id="is_private"
                    name="is_private"
                    type="checkbox"
                    :value="row.is_private"
                    :disabled="!!row.deleted_at"
                    class="my-2"
                    @input="row_toggle_private(row)"
                >
                    {{ $root.translate("Private") }}
                </form-toggle-input>
                <default-button
                    :flavor="row.deleted_at ? 'normal' : 'link'"
                    color="error"
                    :block="true"
                    :title="row.deleted_at ? $root.translate('Re-activate') : $root.translate('De-activate')"
                    @click.prevent="row_toggle_status(row)"
                >
                    <span v-if="row.deleted_at" >
                        <open-icon glyph="trash-restore" /> {{ $root.translate('Re-activate') }}
                    </span>
                    <span v-else>
                        <open-icon glyph="trash" /> {{ $root.translate('De-activate') }}
                    </span>
                </default-button>
            </template>
            <template
                v-slot:tbody="{ row, field }"
            >
                <div
                    v-if="field === 'amount'"
                    :class="{ 'text-strikethrough': !!row.deleted_at }"
                >
                    {{ stripe_amount(row.amount, row.interval, row.interval_count) }}
                </div>
                <span
                    v-else-if="field === 'trial_period_days'"
                    :class="{ 'text-strikethrough': !!row.deleted_at }"
                >
                    <span v-if="!!row.trial_period_days">{{ $root.translate("One day:::{count} days", { count: row.trial_period_days }, row.trial_period_days) }}</span>
                    <span v-else class="text-gray">{{ $root.translate("No Trial") }}</span>
                </span>
                <form-toggle-input
                    v-else-if="field === 'is_private'"
                    id="is_private"
                    name="is_private"
                    type="checkbox"
                    :value="row.is_private"
                    :disabled="!!row.deleted_at"
                    @input="row_toggle_private(row)"
                />
                <div
                    v-else-if="field === '_buttons'"
                    class="text-right"
                >
                    <default-button
                        flavor="link"
                        color="error"
                        size="sm"
                        :title="row.deleted_at ? $root.translate('Re-activate') : $root.translate('De-activate')"
                        @click.prevent="row_toggle_status(row)"
                    >
                        <open-icon v-if="row.deleted_at" glyph="trash-restore" />
                        <open-icon v-else glyph="trash" />
                    </default-button>
                </div>
                <span
                    v-else
                    :class="{ 'text-strikethrough': !!row.deleted_at }"
                >
                    {{ row[field] }}
                </span>
            </template>
        </data-table>
    </page>
</template>

<script type="text/javascript">
import { is_page } from "@/custom/mixins"
import { is_remote_data_table_source, crud_utilities, handles_saving, string_utilities } from "@/nibnut/mixins"

import {
    // FormInput,
    FormToggleInput,
    DefaultButton,
    OpenIcon
} from "@/nibnut/components"

export default {
    mixins: [is_page, is_remote_data_table_source, crud_utilities, handles_saving, string_utilities],
    components: {
        // FormInput,
        FormToggleInput,
        DefaultButton,
        OpenIcon
    },
    methods: {
        row_save (row, value, field) {
            return this.save_field_for_record_id(this.entity, row.id, value, field)
        },
        row_toggle_private (row) {
            this.row_save(row, !row.is_private, "is_private")
        },
        row_toggle_status (row) {
            this.$store.dispatch(
                row.deleted_at ? "RECORD_RESTORE" : "RECORD_DELETE",
                { entity: this.entity, id: row.id }
            ).then(() => {
                if(!this.state.archived) {
                    this.set_state_value("archived", true)
                    this.refresh()
                }
            }).catch(this.receive_error)
        }
    },
    computed: {
        fields () {
            return [
                "fieldset::default"
            ]
        }
    },
    data () {
        return {
            entity: "plan",
            columns: {
                name: { label: this.translate("Name"), sort: "asc", type: "alpha" },
                amount: { label: this.translate("Price"), sort: null, type: "amount" },
                trial_period_days: { label: this.translate("Trial"), sort: null, type: "numeric" },
                is_private: { label: this.translate("Private"), sort: false },
                _buttons: { label: " ", sort: false }
            },
            default_state: {
                per_page: 10,
                page: 1,
                sort_by: "name",
                sort_dir: "asc",
                filter_on: null,
                filter: null,
                archived: true,
                search: "",
                total: 0,
                found: 0
            }
        }
    }
}
</script>

<style lang="scss">
@import "@/assets/sass/variables";

#plans-list {
    .nibnut-data-table {
        td {
            vertical-align: top;
        }
        td.col-max-students {
            width: 200px;
            padding-left: $control-padding-x-sm;
            padding-right: $control-padding-x-sm;

            .btn.btn-link {
                font-size: 0.5rem;
                height: 1.1rem;
                padding: 0;
            }
        }
    }
}
</style>
