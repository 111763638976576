<template>
    <page
        id="plan-editor"
        :title="page_title"
        :back-navigation-fallback="{ title: $root.translate(entityName, {}, 2), href: { name: 'plan.list' } }"
        :status="page_status"
        :waiting="waiting"
        :narrow="true"
        @statused="statused"
    >
        <base-form
            v-if="!!edited_record"
            :has-required-fields="true"
            @submit="create"
        >
            <div class="card">
                <div class="card-body">
                    <form-input
                        id="name"
                        name="name"
                        v-model="edited_record.name"
                        :required="true"
                        :disabled="!!edited_record.id"
                        :saving="saving('name')"
                        :error="has_error('name')"
                        class="mb-4"
                        @input="save"
                    >
                        <template v-slot:label>{{ $root.translate("Name") }}</template>
                    </form-input>
                    <form-input
                        v-if="!!edited_record.id"
                        id="fr_name"
                        name="name"
                        :value="field_translation('name').value"
                        :required="false"
                        class="mb-4"
                        @input="save_field_translation('name', $event)"
                    >
                        <template v-slot:label>{{ $root.translate("Name (FR)") }}</template>
                    </form-input>

                    <div class="columns mb-4">
                        <div class="column col-6 col-sm-12">
                            <div class="flex-bottom">
                                <form-input
                                    id="amount"
                                    name="amount"
                                    type="float"
                                    glyph="dollar-sign"
                                    glyph-position="right"
                                    v-model="edited_record.amount"
                                    :required="true"
                                    :disabled="!!edited_record.id"
                                    :saving="saving('amount')"
                                    :error="has_error('amount')"
                                    @input="save"
                                >
                                    <template v-slot:label>{{ $root.translate("Price") }}</template>
                                </form-input>
                                <label class="form-label mx-2">{{ $root.translate("every") }}</label>
                                <form-input
                                    id="interval_count"
                                    name="interval_count"
                                    type="number"
                                    v-model="edited_record.interval_count"
                                    :required="false"
                                    :disabled="!!edited_record.id"
                                    :saving="saving('interval_count')"
                                    :error="has_error('interval_count')"
                                    @input="save"
                                >
                                    <template v-slot:label>&nbsp;</template>
                                    <template v-slot:right_addon>
                                        <select
                                            :disabled="!!edited_record.id"
                                            class="form-select"
                                            @change="save_field($event.target.value, 'interval')"
                                        >
                                            <option
                                                v-for="interval in intervals"
                                                :key="interval.id"
                                                :value="interval.id"
                                                :selected="interval.id === edited_record.interval"
                                            >
                                                {{ interval.name }}
                                            </option>
                                        </select>
                                    </template>
                                </form-input>
                            </div>
                        </div>
                        <div class="column col-6 col-sm-12">
                            <div class="flex-bottom">
                                <form-toggle-input
                                    id="trial_period"
                                    name="trial_period"
                                    type="checkbox"
                                    :value="!!edited_record.trial_period_days"
                                    :disabled="!!edited_record.id"
                                    @input="toggle_trial_period"
                                >
                                    {{ $root.translate("Trial period") }}
                                    <template v-slot:label><span class="d-invisible">{{ $root.translate("Trial period") }}</span></template>
                                </form-toggle-input>
                                <form-input
                                    v-if="!!edited_record.trial_period_days"
                                    id="trial_period_days"
                                    name="trial_period_days"
                                    type="number"
                                    :min="1"
                                    v-model="edited_record.trial_period_days"
                                    :required="false"
                                    :disabled="!!edited_record.id"
                                    :saving="saving('trial_period_days')"
                                    :error="has_error('trial_period_days')"
                                    @input="save"
                                >
                                    <template v-slot:label><span class="d-invisible">{{ $root.translate("Trial period") }}</span></template>
                                    <template v-slot:right_addon>
                                        <span class="input-group-addon">{{ $root.translate("day(s)") }}</span>
                                    </template>
                                </form-input>
                            </div>
                        </div>
                    </div>
                    <form-toggle-input
                        id="is_private"
                        name="is_private"
                        type="checkbox"
                        :value="edited_record.is_private"
                        :disabled="!!edited_record.id"
                        :saving="saving('is_private')"
                        :error="has_error('is_private')"
                        @input="save"
                    >
                        {{ $root.translate("Private Plan") }}
                        <template v-slot:hint>{{ $root.translate("Private plans are only visible by administrators. Subscribers can not pick them when they sign up.") }}</template>
                    </form-toggle-input>

                    <form-editor
                        id="description"
                        name="description"
                        v-model="edited_record.description"
                        :required="false"
                        class="mt-4"
                    >
                        <template v-slot:label>{{ $root.translate("Description") }}</template>
                    </form-editor>
                </div>

                <div
                    v-if="!edited_record.id"
                    class="card-footer text-center"
                >
                    <default-button
                        :disabled="!!creating"
                        class="mx-2"
                        @click.prevent="$router.go(-1)"
                    >
                        {{ $root.translate("Cancel") }}
                    </default-button>
                    <default-button
                        color="primary"
                        :waiting="!!creating"
                        :disabled="!!creating"
                        class="mx-2"
                        @click.prevent="create"
                    >
                        {{ $root.translate("Save") }}
                    </default-button>
                </div>
            </div>
        </base-form>
    </page>
</template>

<script type="text/javascript">
import { is_record_editor, has_translatable_fields } from "@/custom/mixins"

import {
    BaseForm,
    FormInput,
    FormToggleInput,
    FormEditor,
    DefaultButton
} from "@/nibnut/components"
// import FormQuotaInput from "./FormQuotaInput"

export default {
    mixins: [is_record_editor, has_translatable_fields],
    components: {
        BaseForm,
        FormInput,
        FormToggleInput,
        FormEditor,
        DefaultButton
        // FormQuotaInput
    },
    methods: {
        toggle_trial_period () {
            if(this.edited_record.trial_period_days) this.edited_record.trial_period_days = 0
            else this.edited_record.trial_period_days = 15
        },
        create () {
            this.creating = true
            const { deleted_at, id, stripe_id, ...data } = this.edited_record
            data.amount = parseFloat(data.amount) * 100

            return this.$store.dispatch("CREATE_RECORD", {
                entity: this.entity,
                data
            }).then(record => {
                this.$router.replace({ name: `${this.entity}.list` })
            }).catch(this.receive_error).then(() => {
                this.creating = false
            })
        }
    },
    computed: {
        intervals () {
            return Object.values(this.constants("intervals"))
        }
    },
    data () {
        return {
            entity: "plan",
            entityName: "Plan:::Plans",
            classname: "App\\Plan",
            creating: false
        }
    }
}
</script>

<style lang="scss">
#plan-editor {
    .flex-bottom {
        display: flex;
        align-items: flex-end;

        .form-group {
            margin: 0;
        }
    }
}
</style>
